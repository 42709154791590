export const getCarrierContact = () => {
  const currentTenant = process.env.REACT_APP_TENANT;
  if (currentTenant === "movebees") {
    return "+13307528999";
  } else if (currentTenant === "krupp") {
    return "+13303254832";
  } else if (currentTenant === "summitmoving") {
    return "+13302949246";
  } else if (currentTenant === "rockstarspromoverscomplete") {
    return "+13237625668";
  } else if (currentTenant === "rockstarspromoverspartial") {
    return "+13237625668";
  } else if (currentTenant === "dearmanmoving") {
    return "+18004725706";
  } else if (currentTenant === "royalmovingco") {
    return "+18886349582";
  } else if (currentTenant === "harringtonmovers") {
    return "+19737219257";
  } else if (currentTenant === "elevatedliving") {
    return "+15613534106";
  } else if (currentTenant === "harrington-movers") {
    return "+19737219257";
  } else if (currentTenant === "dependable-movers") {
    return "+16289995741";
  } else if (currentTenant === "justintimemoving") {
      return "+14802130395";    
  } else {
    return "+15613508422";
  }
};
export const getCarrierName = () => {
  const currentTenant = process.env.REACT_APP_TENANT;
  if (currentTenant === "movebees") {
    return "movebees".toUpperCase();
  } else if (currentTenant === "krupp") {
    return "Krupp Moving";
  } else if (currentTenant === "summitmoving") {
    return "Summit Moving";
  } else if (currentTenant === "rockstarspromoverscomplete") {
    return "Rockstar Pro Movers";
  } else if (currentTenant === "rockstarspromoverspartial") {
    return "Rockstar Pro Movers";
  } else if (currentTenant === "dearmanmoving") {
    return "Dearman Moving";
  } else if (currentTenant === "royalmovingco") {
    return "Royal Moving";
  } else if (currentTenant === "harringtonmovers") {
    return "Harrington Movers";
  } else if (currentTenant === "elevatedliving") {
    return "Elevated Living";
  } else if (currentTenant === "harrington-movers") {
    return "Harrington Movers";
  } else if (currentTenant === "dependable-movers") {
    return "Dependable Movers";
  } else if (currentTenant === "justintimemoving") {
    return "JIT Moving";
  
  } else {
    return "WeMove Carrier Network";
  }
};
