export const callSupportContact = () => {
  const currentTenant = process.env.REACT_APP_TENANT;
  if (currentTenant === "movebees") {
    return "+13307528999";
  } else if (currentTenant === "krupp") {
    return "+13303254832";
  } else if (currentTenant === "summitmoving") {
    return "+13302949246";
  } else if (currentTenant === "rockstarspromoverscomplete") {
    return "+13237625668";
  } else if (currentTenant === "rockstarspromoverspartial") {
    return "+13237625668";
  } else if (currentTenant === "dearmanmoving") {
    return "+18004725706";
  } else if (currentTenant === "royalmovingco") {
    return "+18886349582";
  } else if (currentTenant === "harringtonmovers") {
    return "+19737219257";
  } else if (currentTenant === "elevatedliving") {
    return "+15613534106";
  } else if (currentTenant === "harrington-movers") {
    return "+19737219257";
  } else if (currentTenant === "dependable-movers") {
    return "+16289995741";
  } else if (currentTenant === "justintimemoving") {
    return "+14802130395";
  } else {
    return "+15613508422";
  }
};

export const getCarrierAddress = () => {
  const currentTenant = process.env.REACT_APP_TENANT;
  if (currentTenant === "movebees") {
    return "";
  } else if (currentTenant === "krupp") {
    return "2201 Westbelt Dr, Columbus, OH 43228";
  } else if (currentTenant === "summitmoving") {
    return "885 E Tallmadge Ave, Akron, OH 44310";
  } else if (currentTenant === "rockstarspromoverscomplete") {
    return "";
  } else if (currentTenant === "rockstarspromoverspartial") {
    return "";
  } else if (currentTenant === "dearmanmoving") {
    return "961 N Main St, Mansfield, OH 44903";
  } else if (currentTenant === "royalmovingco") {
    return "";
  } else if (currentTenant === "harringtonmovers") {
    return "";
  } else if (currentTenant === "elevatedliving") {
    return "";
  } else if (currentTenant === "harrington-movers") {
    return "202 Rutgers St, Maplewood, NJ 07040";
  } else if (currentTenant === "dependable-movers") {
    return "425 Avenue M, San Francisco, CA, 94130";
  } else {
    return "254 Chapman Road, Newark, DE, 88888";
  }
};
