import { Stack, Typography } from "@mui/material"
const currentTenant = process.env.REACT_APP_TENANT || "";
const ContactSupport = () => {
    return(
        <Stack
  p={{ xs: 1, sm: 2, md: 3 }} // Adjusts padding based on screen size
  alignItems={'center'}
  justifyContent={'center'}
  alignSelf={'center'}
  sx={{
    border: '1px solid black',
    bgcolor: '#c3cfe2',
    height: { xs: 30, sm: 30, md: 30 }, // Adjusts height based on screen size
    width: { xs: '100%', sm: 300, md: 500 }, // Adjusts width based on screen size
    margin: 1,
    borderRadius: 3,
  }}
>
  <Typography
    sx={{ fontStyle: 'italic', fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' } }} // Adjusts font size based on screen size
    fontWeight={600}
    color={'#5858E0'}
  >
    {/* Have Questions? Please Call us at: (561) 350-8422 */}
    Have Questions? Please Call us at:{" "}
    {currentTenant === "justintimemoving" ? "(480) 213-0395" : "(561) 350-8422"}
  </Typography>
</Stack>

    )
}
export default ContactSupport